import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { getRouteType, loadToken } from '@/utils/common'

// 获取下一步
const useSetup = () => {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const loading = ref(false)

  // 获取初始化步骤
  const updateSetupInfo = async () => {
    const res = await store.dispatch('_info/queryMerchantDetail')
    if (res.check()) {
      const data = res.data
      // 合约支付
      const contractStep = data.level ? data.level + 1 : 0
      // 二维码支付
      let qrCodeStep = 0
      if (data.qrCodeSetUp) {
        qrCodeStep = 6
      } else if (!data.managerList || data.managerList.length === 0) {
        qrCodeStep = 0
      } else if (!data.financialList || data.financialList.length === 0) {
        qrCodeStep = 2
      } else if (
        !data.withdrawAddressList ||
        data.withdrawAddressList.length === 0
      ) {
        qrCodeStep = 3
      } else if (!data.contactInfos) {
        qrCodeStep = 4
      } else {
        qrCodeStep = 5
      }
      // 缓存
      const contractSetUp = !data.level || data.level < 5 ? false : true
      const info = {
        contractSetUp,
        contractStep,
        qrCodeSetUp: data.qrCodeSetUp,
        qrCodeStep,
      }
      store.commit('_info/Update_setupInfo', info)
      return info
    }
  }

  // 检查初始化进度
  const checkSetup = async () => {
    await updateSetupInfo()
    const token = loadToken()
    const info = getSkipInfo()
    if (!token) return
    if (!info.isReady) {
      // 跳转初始化步骤
      router.push({
        name: info.setupRoute,
        params: {
          step: info.currentStep,
        },
      })
    } else {
      // 初始化已完成
      const routes = ['Login', 'LoginGoogleAuth', 'QrCodePaymentBind2FA']
      if (routes.indexOf(route.name) !== -1) {
        router.replace(route.query.redirect || { name: info.baseRoute })
      }
    }
  }

  // 跳转下一步
  const goNext = async (options = {}, query = {}) => {
    // 跳转中
    if (loading.value) return
    loading.value = true
    // 参数
    const { nextStep = 0, needCheck = false } = options
    // 需要重新检测是否成功
    if (needCheck) {
      await updateSetupInfo()
    }
    const info = getSkipInfo()
    if (!info.isReady) {
      const step = Math.max(nextStep, info.currentStep)
      // 跳转初始化步骤
      router.push({
        name: info.setupRoute,
        params: {
          step,
        },
        query,
      })
    } else {
      // 初始化已完成
      router.push({ name: info.baseRoute })
    }
  }

  // 跳转信息
  const getSkipInfo = () => {
    const setupInfo = store.state._info.setupInfo
    let next = getRouteType(route.name)
    console.log('🚀 ~ getSkipInfo ~ next:', next)
    if (!next) {
      if (setupInfo.qrCodeSetUp) {
        next = 'qrCode'
      } else if (setupInfo.contractSetUp) {
        next = 'contract'
      } else {
        next = 'qrCode'
      }
    }
    switch (next) {
      case 'contract':
        return {
          baseRoute: 'ContractPayment',
          setupRoute: 'ContractPaymentStep',
          isReady: setupInfo.contractSetUp,
          currentStep: setupInfo.contractStep,
        }
      case 'qrCode':
      default:
        return {
          baseRoute: 'QrCodePayment',
          setupRoute: 'QrCodePaymentStep',
          isReady: setupInfo.qrCodeSetUp,
          currentStep: setupInfo.qrCodeStep,
        }
    }
  }

  return {
    goNext,
    checkSetup,
    updateSetupInfo,
  }
}

export default useSetup
