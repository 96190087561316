import Web3 from 'web3'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import baseABI from '@/web3/abis/erc20.json'
import collectionABI from '@/web3/abis/collection.json'
import remittanceABI from '@/web3/abis/remittance.json'
import depositABI from '@/web3/abis/deposit.json'
import payoutABI from '@/web3/abis/payout.json'
import payoutControlABI from '@/web3/abis/payoutControl.json'
import byteCode from '@/web3/byteCode/eth.js'
import payoutByteCode from '@/web3/byteCode/ethPayout.js'
import {
  toNonExponential,
  toFixed,
  mul,
  divide,
  pow,
  lt,
  eq,
} from '@/utils/calculation'
import { getAddressArr } from '@/utils/common'
import {
  addContract,
  completeContract,
  deleteContract,
  completeDeleteContract,
  addPayoutContract,
} from '@/api/contract'
import { delay } from '@/utils/common'
import { APIResponse, RESPONSE_TYPE } from './response'
import { BalanceNotEnough, ConnectWalletError } from './errors'
import { networkConfig } from '@/constants/networkConfig'

export default class EthAdapter {
  constructor({ wallet, options, chainId }) {
    this.wallet = wallet // 钱包类型
    this.chainId = chainId
    // 参数
    this.options = {
      ...options,
    }
    this.provider = null // 提供者
    this.accountInfo = null // 当前信息
    this.web3 = null
  }

  // 创建provider
  async createProvider() {
    if (this._isMetaMask()) {
      // 多个插件情况
      if (window.ethereum.providers && window.ethereum.providers.length) {
        return Promise.resolve(
          window.ethereum.providers.find((item) => {
            return item.isMetaMask
          })
        )
      }
      return Promise.resolve(window.ethereum)
    } else {
      return EthereumProvider.init(this.options)
    }
  }

  // 连接
  async connect() {
    try {
      this.provider = await this.createProvider()
      if (!this.provider) return
      this.web3 = new Web3(this.provider)
      if (this._isMetaMask()) {
        await this.provider.request({
          method: 'eth_requestAccounts',
        })
      } else {
        await this.provider?.enable()
        console.log('this.provider', this.provider)
      }
      await this.switchNetwork(this.chainId)
    } catch (e) {
      console.log('eeeee', e)
      throw new ConnectWalletError(e?.message || e)
    }
  }

  async send(method, params) {
    try {
      return this.provider.request({
        method,
        params,
      })
    } catch (e) {
      console.log('send', e)
      throw e
    }
  }

  // 切换网络
  async switchNetwork(newChainId) {
    console.log('switchNetwork', newChainId)
    const chainId = `0x${Number(newChainId).toString(16)}`
    try {
      await this.send('wallet_switchEthereumChain', [{ chainId }])
      await this.getAccountInfo()
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          const networkInfo = networkConfig[newChainId]
          await this.send('wallet_addEthereumChain', [
            {
              chainId,
              chainName: networkInfo.name,
              nativeCurrency: networkInfo.nativeCurrency,
              rpcUrls: networkInfo.rpcUrls,
              blockExplorerUrls: networkInfo.blockExplorers,
            },
          ])
        } catch (error) {
          console.log('send error', error)
          throw error
        }
      } else {
        throw switchError
      }
    }
  }

  // 获取用户信息
  async getAccountInfo() {
    try {
      const accounts = await this.web3.eth.getAccounts()
      const chainId = await this.web3.eth.getChainId()
      const data = {
        account: accounts[0] || '',
        chainId: chainId.toString(),
      }
      this.accountInfo = data
      return data
    } catch (err) {
      console.log('getAccountInfo', err)
      throw err
    }
  }

  // 断开连接
  async disconnect() {
    this.provider?.disconnect?.() // 插件无断开方法
    this.provider = null
    this.info = null
  }

  _isMetaMask() {
    // 是否MetaMask
    return this.wallet === 'MetaMask'
  }

  // 签名
  async signMessage(message, address) {
    try {
      let signature = null
      if (this._isMetaMask()) {
        signature = await this.web3.eth.personal.sign(message, address)
      } else {
        signature = await this.send('personal_sign', [message, address])
      }
      return signature
    } catch (e) {
      console.log('catch signMessage', e)
      throw e
    }
  }

  // 获取代币小数位
  async getDecimals(tokenAddress) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const decimals = await contract.methods.decimals().call()
      return decimals
    } catch (error) {
      throw error
    }
  }

  // 获取代币余额
  async getTokenBalance(tokenAddress, address) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const balance = await contract.methods.balanceOf(address).call()
      const decimals = await contract.methods.decimals().call()
      return toNonExponential(divide(balance, pow(10, decimals)))
    } catch (error) {
      throw error
    }
  }

  // 查询津贴
  async getAllowance(tokenAddress, from, address) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const allowance = await contract.methods.allowance(from, address).call()
      return allowance
    } catch (error) {
      throw error
    }
  }

  // 申请津贴
  async sendApprove(tokenAddress, from, address, value) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      await contract.methods.approve(address, value).send({ from })
      return true
    } catch (error) {
      throw error
    }
  }

  // 查询gas费用
  async getEstimatedGasPrice() {
    try {
      const gasPrice = await this.web3.eth.getGasPrice()
      return gasPrice
    } catch (error) {
      throw error
    }
  }

  async getEstimatedGas(tokenAddress, from, address, value) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const gas = await contract.methods
        .transfer(address, value)
        .estimateGas({ from })
      return toFixed(mul(gas, 2), 0)
    } catch (error) {
      throw error
    }
  }

  // 充值
  async _transferToken(
    { abi, address, tokenAddress, amount, orderId },
    msgType,
    promiEvent
  ) {
    const apiResponse = new APIResponse(msgType)
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.web3 || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }
      const from = this.accountInfo.account
      const balance = await this.getTokenBalance(tokenAddress, from)
      console.log('balance', balance)
      if (lt(balance, amount)) {
        // 余额不足
        throw new BalanceNotEnough('balance not enough')
      }
      const { eventEmitter, resolve, reject } = promiEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH))

      const contract = new this.web3.eth.Contract(abi, address)
      console.log('contract', contract)
      const decimals = await this.getDecimals(tokenAddress)
      console.log('decimals', decimals)
      const value = mul(amount, pow(10, decimals))
      console.log('value', value)

      const allowance = await this.getAllowance(tokenAddress, from, address)
      console.log('allowance', allowance)

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH_CONFIRM))

      if (lt(allowance, value)) {
        let approve = true
        // 合约特性，津贴剩余额度不足且不为0，必须要先置为0
        if (!eq(allowance, 0)) {
          approve = await this.sendApprove(tokenAddress, from, address, 0)
          console.log('approve 重置 0', approve)
          if (!approve) return resolve(false)
        }
        // 再重新申请津贴，直接申请津贴会失败
        approve = await this.sendApprove(tokenAddress, from, address, value)
        console.log('sendApprove', approve)
        if (!approve) return resolve(false)
      }

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const gasPrice = await this.getEstimatedGasPrice()
      const gas = await contract.methods
        .transferToken(tokenAddress, value, String(orderId))
        .estimateGas({ from })

      console.log('gas', gasPrice, gas)

      let txHash = null
      contract.methods
        .transferToken(tokenAddress, value, String(orderId))
        .send({ from, gasPrice, gas }, async (err, txid) => {
          if (err) {
            eventEmitter.emit('error', apiResponse.error(err))
            return reject(err)
          }
          if (txid) {
            txHash = txid

            eventEmitter.emit(
              'status',
              apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM),
              {
                txid,
              }
            )
            const res = await this.pollTxidStatus(txHash)
            console.log('polltx', res)
            if (res) {
              eventEmitter.emit(
                'status',
                apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
                  txid,
                })
              )
            } else {
              eventEmitter.emit(
                'error',
                apiResponse.fail(
                  res === null ? RESPONSE_TYPE.TIMEOUT : RESPONSE_TYPE.FAILURE
                ),
                {
                  txid,
                }
              )
            }
            resolve(res)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          if (!txHash) {
            promiEvent.eventEmitter.emit('error', apiResponse.error(error))
            promiEvent.reject(error)
          }
        })
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  transferToken({ address, tokenAddress, amount, orderId }, promiEvent) {
    return this._transferToken(
      {
        abi: depositABI,
        address,
        tokenAddress,
        amount,
        orderId,
      },
      'deposit',
      promiEvent
    )
  }

  // 汇款
  remitToken({ address, tokenAddress, amount, orderId }, promiEvent) {
    return this._transferToken(
      {
        abi: remittanceABI,
        address,
        tokenAddress,
        amount,
        orderId,
      },
      'remittance',
      promiEvent
    )
  }

  // 直接转账
  async transfer({ address, tokenAddress, amount }, promiEvent) {
    const apiResponse = new APIResponse('transfer')
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.web3 || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }
      // 付款地址
      const from = this.accountInfo.account
      const balance = await this.getTokenBalance(tokenAddress, from)
      console.log('balance', balance)
      if (lt(balance, amount)) {
        // 余额不足
        throw new BalanceNotEnough('balance not enough')
      }
      const { eventEmitter, resolve, reject } = promiEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH))

      const decimals = await this.getDecimals(tokenAddress)
      const value = mul(amount, pow(10, decimals))

      let txHash = null
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      contract.methods
        .transfer(address, value)
        .send({ from }, async (err, txid) => {
          if (err) {
            eventEmitter.emit('error', apiResponse.error(err))
            return reject(err)
          }
          if (txid) {
            txHash = txid
            eventEmitter.emit(
              'status',
              apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM),
              {
                txid,
              }
            )
            const res = await this.pollTxidStatus(txHash)
            if (res) {
              eventEmitter.emit(
                'status',
                apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
                  txid,
                })
              )
            } else {
              eventEmitter.emit(
                'error',
                apiResponse.fail(
                  res === null ? RESPONSE_TYPE.TIMEOUT : RESPONSE_TYPE.FAILURE
                ),
                {
                  txid,
                }
              )
            }
            resolve(res ? txHash : false)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          if (!txHash) {
            promiEvent.eventEmitter.emit('error', apiResponse.error(error))
            promiEvent.reject(error)
          }
        })
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 提币
  async withdrawToken({ address, withdrawAddress, withdrawInfo }, promiEvent) {
    const apiResponse = new APIResponse('withdrawal')
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.web3 || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }
      const { eventEmitter, resolve, reject } = promiEvent
      const from = this.accountInfo.account

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const contract = new this.web3.eth.Contract(collectionABI, address)
      console.log('contract', contract)
      const gasPrice = await this.getEstimatedGasPrice()
      const gas = await contract.methods
        .withdrawToken(true, withdrawInfo, withdrawAddress)
        .estimateGas({ from })
      console.log('gasPrice', gasPrice, gas)

      let txHash = null
      contract.methods
        .withdrawToken(true, withdrawInfo, withdrawAddress)
        .send({ from, gasPrice, gas }, async (err, txid) => {
          if (err) {
            eventEmitter.emit('error', apiResponse.error(err))
            return reject(err)
          }
          if (txid) {
            txHash = txid
            eventEmitter.emit(
              'status',
              apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM),
              {
                txid,
              }
            )
            const res = await this.pollTxidStatus(txHash)
            console.log('polltx', res)
            if (res) {
              eventEmitter.emit(
                'status',
                apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
                  txid,
                })
              )
            } else {
              eventEmitter.emit(
                'error',
                apiResponse.fail(
                  res === null ? RESPONSE_TYPE.TIMEOUT : RESPONSE_TYPE.FAILURE
                ),
                {
                  txid,
                }
              )
            }
            resolve(res ? txHash : false)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          if (!txHash) {
            promiEvent.eventEmitter.emit('error', apiResponse.error(error))
            promiEvent.reject(error)
          }
        })
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 部署合约
  async deployContract(options, successHandle, errorHandle, callbackHandle) {
    const apiResponse = new APIResponse('deployment')
    try {
      if (!this.web3 || !this.accountInfo) {
        throw new Error('invalid provider')
      }
      const { withdrawalList, ownerList, newGatewayAddress } = options
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const from = this.accountInfo.account
      const contract = new this.web3.eth.Contract(collectionABI)

      // 合约参数：( withdrawArr: 提币地址列表, ownerArr: 财务人员列表 )
      const withdrawArr = getAddressArr(withdrawalList)
      const ownerArr = getAddressArr(ownerList)
      const contractArgs = [withdrawArr, ownerArr, newGatewayAddress]

      const gasPrice = await this.getEstimatedGasPrice()
      const gas = await contract
        .deploy({ data: '0x' + byteCode, arguments: contractArgs })
        .estimateGas({ from })

      console.log('gasPrice', gasPrice, gas)

      let newContractId = null
      // 开始部署
      contract
        .deploy({ data: '0x' + byteCode, arguments: contractArgs })
        .send({ from, gasPrice, gas })
        // 交易哈希值
        .on('transactionHash', (txId) => {
          // 添加合约
          callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM), {
            txid: txId,
          })
          addContract({ ownerList, txId, withdrawalList }).then((res) => {
            newContractId = res.data
          })
        })
        // 部署合约的地址
        .on('receipt', (receipt) => {
          // 完成部署合约
          callbackHandle(apiResponse.ok(RESPONSE_TYPE.SUCCESS), {
            txid: receipt.transactionHash,
          })
          if (newContractId) {
            completeContract({
              contractAddress: receipt.contractAddress,
              id: newContractId,
            }).then(() => {
              successHandle(receipt.contractAddress.toLowerCase())
            })
          }
        })
        // 报错
        .on('error', (error) => {
          errorHandle()
          callbackHandle(apiResponse.error(error))
          console.log('error:', error)
        })
    } catch (error) {
      errorHandle()
      callbackHandle(apiResponse.error(error))
      console.log('error', error)
    }
  }

  // 部署代付合约
  async deployPayoutContract(
    options,
    successHandle,
    errorHandle,
    callbackHandle
  ) {
    const apiResponse = new APIResponse('deployment')
    try {
      if (!this.web3 || !this.accountInfo) {
        throw new Error('invalid provider')
      }
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const from = this.accountInfo.account
      const contract = new this.web3.eth.Contract(payoutABI)
      const {
        ownerList,
        newGatewayAddress,
        newFeeTokenAddress,
        newFeeToAddress,
      } = options

      // 合约参数：(ownerArr: 财务人员列表 )
      const ownerArr = getAddressArr(ownerList)
      const argumentsArr = [
        ownerArr,
        newGatewayAddress,
        newFeeTokenAddress,
        newFeeToAddress,
      ]

      const gasPrice = await this.getEstimatedGasPrice()
      const gas = await contract
        .deploy({ data: '0x' + payoutByteCode, arguments: argumentsArr })
        .estimateGas({ from })

      console.log('gasPrice', gasPrice, gas)

      let newContractId = null

      // 开始部署
      contract
        .deploy({
          data: '0x' + payoutByteCode,
          arguments: argumentsArr,
        })
        .send({ from, gas, gasPrice })
        // 交易哈希值
        .on('transactionHash', (txId) => {
          // 添加合约
          callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM), {
            txid: txId,
          })
          // 添加合约
          addPayoutContract({ ownerList, txId }).then((res) => {
            newContractId = res.data
          })
        })
        // 部署合约的地址
        .on('receipt', (receipt) => {
          // 完成部署合约
          callbackHandle(apiResponse.ok(RESPONSE_TYPE.SUCCESS), {
            txid: receipt.transactionHash,
          })
          if (newContractId) {
            completeContract({
              contractAddress: receipt.contractAddress,
              id: newContractId,
            }).then(() => {
              successHandle(receipt.contractAddress.toLowerCase())
            })
          }
        })
        // 报错
        .on('error', (error) => {
          errorHandle()
          callbackHandle(apiResponse.error(error))
          console.log('error:', error)
        })
    } catch (error) {
      errorHandle()
      callbackHandle(apiResponse.error(error))
      console.log('error', error)
    }
  }

  // 烧毁合约
  async destructContract(params, successHandle, errorHandle, callbackHandle) {
    const apiResponse = new APIResponse('destruct')
    try {
      if (!this.web3 || !this.accountInfo) {
        throw new Error('invalid provider')
      }
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION))
      const from = this.accountInfo.account
      const contract =
        params.type && params.type == 'payout'
          ? new this.web3.eth.Contract(payoutABI, params.address)
          : new this.web3.eth.Contract(collectionABI, params.address)

      const gasPrice = await this.getEstimatedGasPrice()
      const gas = await contract.methods.setActiveFlag().estimateGas({ from })

      contract.methods
        .setActiveFlag()
        .send({ from, gasPrice, gas })
        .on('transactionHash', (txId) => {
          callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM), {
            txid: txId,
          })
          // 删除合约
          deleteContract({ id: params.id, txId })
        })
        // 部署合约的地址
        .on('receipt', (receipt) => {
          callbackHandle(apiResponse.ok(RESPONSE_TYPE.SUCCESS), {
            txid: receipt.transactionHash,
          })
          // 完成销毁合约
          completeDeleteContract({ param: params.id }).then(() => {
            successHandle()
          })
        })
        .on('error', (error) => {
          errorHandle()
          callbackHandle(apiResponse.error(error))
          console.log('error:', error)
        })
    } catch (error) {
      errorHandle && errorHandle()
      callbackHandle(apiResponse.error(error))
      console.log('error', error)
    }
  }

  // 校验格式是否正确
  isValidAddress(address) {
    return Web3.utils.isAddress(address)
  }

  // 轮询txid方法
  async pollTxidStatus(txHash, maxAttempts = 50, waitInterval = 5000) {
    return new Promise(async (resolve, reject) => {
      await delay(200)
      let attempts = 0
      while (attempts < maxAttempts) {
        try {
          const txInfo = await this.web3.eth.getTransactionReceipt(txHash)
          if (txInfo) {
            resolve(txInfo.status)
            return
          }
          await delay(waitInterval)
          attempts++
        } catch (error) {
          // 忽略网络错误等
          await delay(waitInterval)
          attempts++
        }
      }
      resolve(null)
    })
  }

  // 获取代付合约地址转账钱包地址
  async getPayoutWalletAddress({ contractAddress, payoutControl }) {
    try {
      const contract = new this.web3.eth.Contract(
        payoutControlABI,
        payoutControl
      )
      const res = await contract.methods.getPayout(contractAddress).call()
      return res
    } catch (error) {
      throw error
    }
  }

  async setPayoutWalletAddress(
    { contractAddress, autoAddress, payoutControl },
    promiEvent
  ) {
    const apiResponse = new APIResponse('integration')
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.web3 || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }
      const { eventEmitter, resolve, reject } = promiEvent
      const from = this.accountInfo.account

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const contract = new this.web3.eth.Contract(
        payoutControlABI,
        payoutControl
      )
      console.log('contract', contractAddress, autoAddress, payoutControl)

      let txHash = null
      const res = !autoAddress
        ? contract.methods.deletePayout(contractAddress)
        : contract.methods.setPayout(autoAddress, contractAddress)
      res
        .send({ from }, async (err, txid) => {
          if (err) {
            eventEmitter.emit('error', apiResponse.error(err))
            return reject(err)
          }
          if (txid) {
            txHash = txid
            eventEmitter.emit(
              'status',
              apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM),
              {
                txid,
              }
            )
            const res = await this.pollTxidStatus(txHash)
            console.log('polltx', res)
            if (res) {
              eventEmitter.emit(
                'status',
                apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
                  txid,
                })
              )
            } else {
              eventEmitter.emit(
                'error',
                apiResponse.fail(
                  res === null ? RESPONSE_TYPE.TIMEOUT : RESPONSE_TYPE.FAILURE
                ),
                {
                  txid,
                }
              )
            }
            resolve(res ? txHash : false)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          if (!txHash) {
            promiEvent.eventEmitter.emit('error', apiResponse.error(error))
            promiEvent.reject(error)
          }
        })
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 获取自动代付授权金额
  async getPayoutLimit({ contractAddress }) {
    try {
      const contract = new this.web3.eth.Contract(payoutABI, contractAddress)
      const res = await contract.methods.payoutLimit().call()
      return toNonExponential(divide(res, pow(10, 18)))
    } catch (error) {
      throw error
    }
  }

  // 设置自动代付授权金额
  setPayoutLimit({ contractAddress, limit }, promiEvent) {
    return this.setPayoutMethod(
      {
        contractAddress,
        method: 'setPayoutLimit',
        value: mul(limit, pow(10, 18)),
        msgType: 'approval',
      },
      promiEvent
    )
  }

  // 获取代付合约方法
  async getPayoutMethod({ contractAddress, method }) {
    try {
      const contract = new this.web3.eth.Contract(payoutABI, contractAddress)
      const res = await contract.methods[method]().call()
      return res
    } catch (error) {
      throw error
    }
  }

  // 设置合约方法
  async setPayoutMethod(
    { contractAddress, method, value, msgType },
    promiEvent
  ) {
    const apiResponse = new APIResponse(msgType)
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.web3 || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }

      const { eventEmitter, resolve, reject } = promiEvent
      const from = this.accountInfo.account

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))
      const contract = new this.web3.eth.Contract(payoutABI, contractAddress)
      console.log('contract', contractAddress, value)
      let txHash = null

      contract.methods[method](value)
        .send({ from }, async (err, txid) => {
          if (err) {
            eventEmitter.emit('error', apiResponse.error(err))
            return reject(err)
          }
          if (txid) {
            txHash = txid
            eventEmitter.emit(
              'status',
              apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM),
              {
                txid,
              }
            )
            const res = await this.pollTxidStatus(txHash)
            console.log('polltx', res)
            if (res) {
              eventEmitter.emit(
                'status',
                apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
                  txid,
                })
              )
            } else {
              eventEmitter.emit(
                'error',
                apiResponse.fail(
                  res === null ? RESPONSE_TYPE.TIMEOUT : RESPONSE_TYPE.FAILURE
                ),
                {
                  txid,
                }
              )
            }
            resolve(res ? txHash : false)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          if (!txHash) {
            promiEvent.eventEmitter.emit('error', apiResponse.error(error))
            promiEvent.reject(error)
          }
        })
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 设置授权/拒绝方法
  confirmPendingOrder({ contractAddress, value, msgType }, promiEvent) {
    return this.setPayoutMethod(
      {
        contractAddress,
        method:
          msgType === 'reject' ? 'cancelPendingOrder' : 'confirmPendingOrder',
        value,
        msgType,
      },
      promiEvent
    )
  }
}
