import request from '@/utils/request'

/* 登录 */
export function login(data) {
  return request({
    url: '/admin/mng/login',
    method: 'post',
    data,
  })
}

/* 账户资金 */
export function getAccountBalance(data) {
  return request({
    url: '/account/mng/account/find',
    method: 'post',
    data,
  })
}

/* 收款账户资金 */
export function getCollectionBalance(data) {
  return request({
    url: '/account/mng/account/find/one',
    method: 'post',
    data,
  })
}

/* 收款账户资产列表*/
export function getCollectionAssets(data) {
  return request({
    url: '/account/mng/account/find/list',
    method: 'post',
    data,
  })
}

/* 获取统计数据 */
export function getSummaryData(data) {
  return request({
    url: '/wallet/mng/reportAll',
    method: 'post',
    data,
  })
}

/* 余额明细分页列表 */
export function getAccountWaterPage(data) {
  const param = {
    url: '/account/mng/account/water/page',
    method: 'post',
    data,
  }
  return request(param)
}

/* 获取收款记录 */
export function getReceivedData(data) {
  return request({
    url: '/wallet/mng/receivied',
    method: 'post',
    data,
  })
}

/* 获取提现记录 */
export function getWithdrawData(data) {
  return request({
    url: '/wallet/mng/outlog',
    method: 'post',
    data,
  })
}

/* 获取代付记录 */
export function getPayOutData(data) {
  return request({
    url: '/wallet/mng/payout',
    method: 'post',
    data,
  })
}

/* 获取转账记录 */
export function getTransactionData(data) {
  return request({
    url: '/wallet/mng/detail',
    method: 'post',
    data,
  })
}

// 代付订单webhook补发
export function manualPayoutWebhook(data) {
  return request({
    url: '/wallet/mng/payout/callback',
    method: 'post',
    data,
  })
}

// 上传文件
export function uploadFile(data) {
  return request({
    url: '/admin/mng/oss/upload',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

/* 判断地址是否有注册 */
export async function checkLogin(data) {
  return request({
    url: '/admin/mng/addressBeRegisted',
    method: 'post',
    data,
  })
}

/* 切换网络 */
export function switchNetwork(data) {
  return request({
    url: '/admin/mng/switchNetWork',
    method: 'post',
    data,
  })
}

/* 邮箱登录-V4.1.0 */
export function loginEmail(data) {
  return request({
    url: '/admin/mng/login/email',
    method: 'post',
    data,
  })
}

/* 忘记密码-V4.1.0 */
export function forgetPassword(params) {
  return request({
    url: '/admin/mng/merchant/forget/password',
    method: 'get',
    params,
  })
}

/* 重置密码-V4.1.0 */
export function resetPassword(params) {
  return request({
    url: '/admin/mng/merchant/reset/password',
    method: 'get',
    params,
  })
}

/* 获取第三方授权链接-v4.3.0 */
export function getThirdLoginRender({ source, ...data }) {
  return request({
    url: '/admin/mng/third/login/render/' + source,
    method: 'post',
    data,
  })
}

/* 第三方授权完成-v4.3.0 */
export function loginThirdCallback({ source, ...data }) {
  return request({
    url: '/admin/mng/third/login/callback/' + source,
    method: 'post',
    data,
  })
}

/* 登录接口(google验证器)-v4.3.0 */
export function loginWithGoogle(data) {
  return request({
    url: '/admin/mng/third/login/google/authorize',
    method: 'post',
    data,
  })
}
