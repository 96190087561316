import i18n from '@/i18n/i18n'

// unknow code
export const DEFAULT_ERROR_CODE = 1006

// 交易类型
export const RESPONSE_TYPE = {
  AUTH: 1001, // 授权
  AUTH_CONFIRM: 1002, // 授权确认
  TRANSACTION: 1003, // 交易
  TRANSACTION_CONFIRM: 1004, // 交易确认
  SUCCESS: 1005, // 交易成功
  FAILURE: 1006, // 交易失败
  BALANCE_NOT_ENOUGH: 4003, // 余额不足
  TIMEOUT: 4005, // 超时
}

// 通过code获取message
export const getStatusMessage = (code, msgType) => {
  const { te, t } = i18n.global
  return te('msg.' + code)
    ? t('msg.' + code, { msgType: t('msgType.' + msgType) })
    : t('msg.' + DEFAULT_ERROR_CODE)
}

// 格式化Error数据
export const getErrorTextFromError = (error, msgType) => {
  // tron返回字符串
  if (typeof error === 'string') {
    error = {
      message: error,
    }
  }
  if (
    error.message ===
      'MetaMask Tx Signature: User denied transaction signature.' ||
    error.message ===
      'MetaMask Message Signature: User denied message signature.' ||
    error.message === 'Confirmation declined by user' ||
    error.message === 'The QR window is closed.' ||
    error.message === 'User rejected the transaction'
  ) {
    error.errorCode = 4001
  } else {
    error.errorCode = error?.errorCode || DEFAULT_ERROR_CODE
  }
  return {
    errorCode: error.errorCode,
    rowMessage: error, // 源错误消息
    // message: getStatusMessage(error.errorCode, msgType),
  }
}

// 定义一个统一的返回对象
export class APIResponse {
  constructor(msgType) {
    this.msgType = msgType
  }
  ok(messageCode, data) {
    return {
      success: true,
      code: messageCode,
      msgType: this.msgType,
      // message: getStatusMessage(messageCode, this.msgType),
      data,
    }
  }
  fail(messageCode, data) {
    return {
      success: false,
      code: messageCode,
      msgType: this.msgType,
      // message: getStatusMessage(messageCode, this.msgType),
      data,
    }
  }
  error(error, data) {
    const { errorCode, message, rowMessage } = getErrorTextFromError(
      error,
      this.msgType
    )
    return {
      success: false,
      code: errorCode,
      // message,
      msgType: this.msgType,
      data,
      rowMessage,
    }
  }
}
