import axios from 'axios'
import CheckAPI from './checkAPI'
import { loadToken, downloadBlob, updateToken } from '@/utils/common'
import { disconnect, logout } from './web3Hooks'
import { getDefaultLocal } from '@/i18n/i18n'
import store from '@/store'

// let router = null
// export const setRouter = r => (router = r)

// let store = null
// export const setStore = s => (store = s)

const apiURL = process.env.VUE_APP_apiURL

// create an axios instance
const service = axios.create({
  baseURL: apiURL, // url = base url + request url
  // baseURL: 'http://backstage-b2b.ufcfan.org/',
  timeout: 60000, // request timeout
})
const headers = service.defaults.headers
headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// request interceptor
service.interceptors.request.use(
  (config) => {
    // 请求头设置
    config.headers['accessToken'] = loadToken()
    config.headers['lang'] = getDefaultLocal()
    config.toastErr = config.toastErr ?? true
    // 导出参数设置输出类型
    if (config.data?.export === true) {
      config.responseType = 'blob'
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const { data, config, headers, request } = response
    // 保存token
    if (headers.accesstoken) {
      updateToken(headers.accesstoken)
    }

    if (headers['content-disposition']) {
      const fileName = headers['content-disposition'].split('=')[1]
      const result = {
        fileName: decodeURIComponent(fileName),
        data: data,
      }
      downloadBlob(result.data, result.fileName) // 导出文件
      return result
    } else {
      const result = new CheckAPI(data)
      // 是否维护中
      const isMaintenance = result.code === '000114'
      if (!request.responseURL.endsWith('/mconfig/upgrade')) {
        store.commit('_config/Update_maintenance', isMaintenance ? 1 : 2) // 维护状态
      }

      // 登录已过期
      const isNeedDisconnect =
        result.code === '000109' ||
        result.code === '80060016' ||
        result.code === '000108'

      if (isNeedDisconnect) {
        disconnect()
        logout()
      }

      if (
        !result.check() &&
        config.toastErr &&
        !isMaintenance &&
        !isNeedDisconnect
      ) {
        result.toast()
      }
      return result
    }
  },
  (error) => {
    const { config, response } = error
    if (config.toastErr && config.url && response?.config) {
      const result = new CheckAPI(response.data)
      if (!result.check()) result.toast()
      return Promise.resolve(result)
    }
    return Promise.resolve(new CheckAPI(response?.data || {}))
  }
)

export default service
