import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "h-20 pl-4 pr-7 sm:pl-7 flex justify-between border-b border-bd max-xl:bg-skyBlue"
};
const _hoisted_2 = {
  class: "flex items-center max-xl:w-full max-xl:justify-between"
};
const _hoisted_3 = {
  class: "flex items-center"
};
import Logo from './logo.vue';
import NavList from './navList.vue';
import AccountBox from './accountBox.vue';
import Language from './language.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import useMediaQuery from '@/hooks/useMediaQuery';
export default {
  __name: 'index',
  emits: ['toggle-menu'],
  setup(__props, {
    emit
  }) {
    const store = useStore();
    const isLgScreen = useMediaQuery('lg');
    const isXsScreen = useMediaQuery('xs');
    const loginInfo = computed(() => store.state._info.loginInfo);
    const handleClickMenu = () => {
      emit('toggle-menu');
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Logo, {
        class: "mr-6"
      }), !_unref(isLgScreen) ? (_openBlock(), _createBlock(NavList, {
        key: 0,
        class: "flex items-center h-full"
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [!_unref(isXsScreen) && _unref(loginInfo)?.address ? (_openBlock(), _createBlock(AccountBox, {
        key: 0,
        type: _unref(loginInfo)?.addressType
      }, null, 8, ["type"])) : _createCommentVNode("", true), _unref(isLgScreen) ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "ml-4 w-6 h-4 cursor-pointer",
        src: "/images/common/menu.svg",
        onClick: handleClickMenu
      })) : _createCommentVNode("", true), _createVNode(Language, {
        class: "ml-4"
      })])]);
    };
  }
};