import dayjs from 'dayjs'

export const PWD_REG =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?^&().,/+=_-])[A-Za-z\d$@$!%*#?^&.,/+=_-]{8,18}$/ // 至少8个字符，至少1个大写字母，至少1个字母，1个数字和1个特殊字符

export function isMobile() {
  return /AppleWebKit.*Mobile.*/gi.test(navigator.userAgent)
}

/* 日期格式化 */
export function formatTime(timestamp) {
  if (!timestamp) {
    return ''
  }
  let date = new Date(parseInt(timestamp))
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  let D =
    date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
  let h =
    date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
  let m =
    date.getMinutes() < 10
      ? '0' + date.getMinutes() + ':'
      : date.getMinutes() + ':'
  let s =
    date.getSeconds() < 10
      ? '0' + date.getSeconds() + ''
      : date.getSeconds() + ''
  return Y + M + D + h + m + s
}

/* 获取前几日日期范围 */
export function getDateRange(day = -30) {
  let startTime, endTime
  const startToday = new Date().setHours(0, 0, 0, 0)
  const endToday = new Date().setHours(23, 59, 59, 59)
  const dayTime = 24 * 60 * 60 * 1000 * day

  if (day >= 0) {
    startTime = startToday
    endTime = endToday + dayTime
  } else {
    startTime = startToday + dayTime
    endTime = endToday
  }

  return [startTime, endTime]
}

/* 获取本月日期范围 */
export function getCurrentMonthRange() {
  return [dayjs().startOf('month').valueOf(), dayjs().endOf('month').valueOf()]
}

export function getMonthsRange(pointDate, timeRange) {
  const point = new Date(pointDate)
  const year = point.getFullYear()
  const month = point.getMonth() + 1
  const day = point.getDate()

  let forwardYear = year
  let forwardMonth = month - timeRange
  if (forwardMonth < 0) {
    forwardYear = forwardYear - 1
    forwardMonth = forwardMonth + 12
  }
  const forwardTime = getTimeFromDate(forwardYear, forwardMonth, day)

  let backwardYear = year
  let backwardMonth = month + timeRange
  if (backwardMonth > 12) {
    backwardYear = backwardYear + 1
    backwardMonth = backwardMonth - 12
  }
  const backwardTime = getTimeFromDate(backwardYear, backwardMonth, day)
  return [forwardTime, backwardTime]
}

function getTimeFromDate(year, month, day) {
  const days = new Date(year, month, 0).getDate()
  const D = day < days ? day : days
  const date = year + '-' + month + '-' + D
  const time = new Date(date).getTime()
  return time
}

export const getAddressArr = (list) => {
  return list.map((item) => item.address)
}

export const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

export const localSet = (key, value) => {
  if (checkLocalStorage()) {
    window.localStorage.setItem(key, value)
  } else {
    let Days = 30
    let exp = new Date()
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
    document.cookie =
      key + '=' + escape(value) + ';expires=' + exp.toGMTString()
  }
}

export const localGet = (key) => {
  if (checkLocalStorage()) {
    return window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : null
  } else {
    return getCookie(key)
  }
}

export const localClear = (key) => {
  if (checkLocalStorage()) {
    window.localStorage.removeItem(key)
  } else {
    let exp = new Date()
    exp.setTime(exp.getTime() - 1)
    let cval = getCookie(key)
    if (cval != null)
      document.cookie = key + '=' + cval + ';expires=' + exp.toGMTString()
  }
}

/* 确认是否支持Localstorage */
function checkLocalStorage() {
  return window.localStorage &&
    (window.localStorage.setItem('a', 123),
    window.localStorage.getItem('a') == 123)
    ? true
    : false
}

function getCookie(name) {
  let arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) {
    return unescape(arr[2])
  } else {
    return null
  }
}

/* 下载文件 */
export function downloadByUrl(url, fileName) {
  // 创建a标签
  let alink = document.createElement('a')
  alink.style.display = 'none'
  alink.download = fileName
  alink.href = url
  // 添加a标签
  document.body.appendChild(alink)
  alink.click()
  document.body.removeChild(alink)
}

export function downloadBlob(data, fileName) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(data, fileName)
  } else {
    // new Blob
    const blob = new Blob([data])
    const url = URL.createObjectURL(blob)
    downloadByUrl(url, fileName)
    URL.revokeObjectURL(url)
  }
}

// 延迟
export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getEllipsisData(address, begin = 5, end = 5) {
  const beginStr = address.slice(0, begin)
  const endStr = address.slice(-end)
  return beginStr + '...' + endStr
}

export function parseJSON(value, defaultValue = null) {
  try {
    return JSON.parse(value) || defaultValue
  } catch (e) {
    return defaultValue
  }
}

// 获取本地存储json字符串类型
export function localGetJSON(key, defaultValue = null) {
  const value = localStorage.getItem(key)
  return parseJSON(value, defaultValue)
}

export function loadToken() {
  return localGet('token')
}

// 保存token
export function updateToken(token) {
  localSet('token', token)
}

// 解析jwt token
export function parseToken(defaultValue = null) {
  try {
    const token = loadToken()
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    const payload = JSON.parse(jsonPayload)
    const result = JSON.parse(payload.info)
    const address = result.address
    result.addressType = address.includes('@')
      ? 'email'
      : address.startsWith('0x')
      ? 'eth'
      : 'tron'
    return result
  } catch (e) {
    return defaultValue
  }
}

// 将以base64的图片url数据转换为Blob
export function base64ToBlob(base64Data) {
  const dataArr = base64Data.split(',')
  const imageType = dataArr[0].match(/:(.*?);/)[1]
  const textData = window.atob(dataArr[1])
  const arrayBuffer = new ArrayBuffer(textData.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < textData.length; i++) {
    uint8Array[i] = textData.charCodeAt(i)
  }
  return new Blob([arrayBuffer], { type: imageType })
}

export function getRouteType(routeName) {
  if (routeName) {
    if (routeName.indexOf('ContractPayment') !== -1) {
      return 'contract'
    } else if (routeName.indexOf('QrCodePayment') !== -1) {
      return 'qrCode'
    }
  }
  return ''
}

/* 判断是否符合密码要求 */
export function isPassword(str) {
  return PWD_REG.test(str)
}

/** 对象过滤 */
export function filterObject(obj, predicate) {
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach((key) => {
    if (predicate(key, obj[key])) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}
