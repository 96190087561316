import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6203051d"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-ga", "onClick"];
const _hoisted_2 = {
  class: "text-sm"
};
import { ref, watchEffect, computed, useAttrs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DOCS_URL } from '@/constants/config';
import { loadToken } from '@/utils/common';
import toast from '@/utils/toast';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'navList',
  props: {
    type: {
      type: String,
      default: 'line'
    }
  },
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const {
      t
    } = useI18n({
      useScope: 'global'
    });
    const {
      onClick
    } = useAttrs();

    // 当前菜单
    const activeNav = ref('QrCodePayment');

    // 菜单列表
    const navList = computed(() => {
      const info = store.state._info.setupInfo;
      return [{
        text: 'route.contractPayment',
        val: 'ContractPayment',
        path: info.contractSetUp ? '/' : '/contract-payment/step/' + info.contractStep,
        icon: 'bm bm-contract'
      }, {
        text: 'route.qrCodePayment',
        val: 'QrCodePayment',
        path: info.qrCodeSetUp ? '/qrcode-payment' : '/qrcode-payment/step/' + info.qrCodeStep,
        icon: 'bm bm-contract'
      }, {
        text: 'route.docs',
        path: DOCS_URL,
        val: 'Docs',
        icon: 'bm bm-catalog'
      }];
    });

    // 识别菜单的当前效果
    watchEffect(() => {
      const routeName = route.name === 'Login' ? 'QrCodePayment' : route.name || '';
      const findName = navList.value.find(el => routeName.indexOf(el.val) !== -1);
      if (findName) {
        activeNav.value = findName.val;
      }
    });

    // 点击菜单
    const launchNav = item => {
      const connected = store.state._info.connectInfo.connected;
      const contractFlag = store.state._info.merchantInfo.contractFlag;
      const token = loadToken();
      if (item.val === 'ContractPayment' && token && contractFlag === false) {
        const addressType = store.state._info.loginInfo.addressType;
        const msg = {
          email: t('login.contractRoleTipsByEmail'),
          eth: t('login.contractRoleTipsByTron'),
          tron: t('login.contractRoleTipsByEth')
        };
        return toast.alert(msg[addressType]);
      }
      if (item.path.startsWith('http')) {
        window.open(item.path);
      } else if (!token && !connected && item.val === 'QrCodePayment') {
        router.push({
          name: 'Login'
        });
      } else {
        router.push(item.path);
      }
      onClick && onClick();
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("nav", {
        class: _normalizeClass(__props.type + '-nav')
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navList), (item, index) => {
        return _openBlock(), _createElementBlock("a", {
          key: index,
          class: _normalizeClass(['nav-item', {
            active: activeNav.value === item.val
          }]),
          "data-ga": `menu,${item.path}`,
          onClick: $event => launchNav(item)
        }, [_createElementVNode("i", {
          class: _normalizeClass(["mr-3 text-xl", [item.icon]])
        }, null, 2), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(item.text)), 1)], 10, _hoisted_1);
      }), 128))], 2);
    };
  }
};