import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68b75c16"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'networkIcon',
  props: {
    size: {
      type: String,
      default: 'middle'
    },
    name: String
  },
  setup(__props) {
    const props = __props;
    const store = useStore();
    const network = computed(() => {
      return props.name || store.state._config.contractConfig.network;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['flex items-center justify-center', __props.size])
      }, [_unref(network) ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "h-full",
        src: '/images/network/' + _unref(network).toLowerCase() + '.svg'
      }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "h-full",
        src: '/images/common/warning.svg'
      }, null, 8, _hoisted_2))], 2);
    };
  }
};